import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PlacesAutocomplete, { getLatLng } from 'react-places-autocomplete';

import MapCordinateContext from '@/context/MapCordinateContext';
import {
  SEARCH_PLACEHOLDER_EN,
  SEARCH_PLACEHOLDER_EN_MOB,
  SEARCH_PLACEHOLDER_FR,
  SEARCH_PLACEHOLDER_FR_MOB,
} from '@/utils/constants';

const SearchInput = () => {
  const { locale }: any = useRouter();
  const [address, setAddress] = useState('');

  const router = useRouter();
  const searchOptions = {
    componentRestrictions: { country: ['ca'] },
  };

  const cords = useContext(MapCordinateContext);
  const handleChange = async (value: string) => {
    setAddress(value);
  };

  const handleClick = (searchItem: any) => {
    const addr = searchItem && searchItem?.length ? searchItem : address;
    const value = addr && addr?.length ? addr.split(',') : address.split(',');
    if (value) {
      /* eslint-disable*/
      let address = addr;
      const getGeocode = () => {
        const googleMapsGeocoder = new google.maps.Geocoder();
        googleMapsGeocoder.geocode(
          {
            address,
            componentRestrictions: {
              country: 'ca',
            },
          },
          (results) => {
            getLatLng(results[0]).then((latLng) => {
              if(address.includes('#')){
                address = address.replace('#','')
              }
              cords.setLatLangs(latLng);
              cords?.setSisterInput(false);
              router.push(
                  `/self-storage/?location=${address}&lat=${latLng.lat}&lng=${latLng.lng}`
              );
            });
          }
        );
      }
      if (typeof google !== 'undefined' && google.maps) {
        getGeocode();
      } else {
        const interval = setInterval(() => {
          if (typeof google !== 'undefined' && google.maps) {
            clearInterval(interval);
            getGeocode();
          }
        }, 250);
      }
    }
  };

  const handleSelect = (value: string) => {
    setAddress(value);
    cords.setAddresses(value);
      handleClick(value);
  };

  useEffect(() => {
    if (address) {
      setAddress(address);
    }
  }, [address]);

  // Log error status and clear dropdown when Google Maps API returns an error.
  const onError = (status: any, clearSuggestions: any) => {
    console.log('Google Maps API returned error with status: ', status);
    clearSuggestions();
  };
  return (
    <>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={searchOptions}
        googleCallbackName="initTwo"
        onError={onError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              className="form-control d-none d-lg-block d-md-block"
              {...getInputProps({
                disabled: false,
                placeholder: `${
                  locale === 'en'
                    ? SEARCH_PLACEHOLDER_EN
                    : SEARCH_PLACEHOLDER_FR
                }`,
              })}
            />

            <input
              className="form-control d-block d-lg-none d-md-none"
              {...getInputProps({
                disabled: false,
                placeholder: `${
                  locale === 'en'
                    ? SEARCH_PLACEHOLDER_EN_MOB
                    : SEARCH_PLACEHOLDER_FR_MOB
                }`,
              })}
            />
            <button
              className="btn search-btn find-units"
              type="button"
              onClick={handleClick}
            >
              <FormattedMessage id="find-unit" />
            </button>
            <button
              className="btn search-btn search-units"
              type="button"
              onClick={handleClick}
            >
              <FormattedMessage id="buttons.search" />
            </button>
            <div className="suggestions-dropdown">
              {loading ? <div>...loading</div> : null}

              {suggestions.map((suggestion, index) => {
                const style = {
                  backgroundColor: suggestion.active ? '#e9ecef' : '#fff',
                };

                return (
                  <>
                    <div
                      className="options"
                      {...getSuggestionItemProps(suggestion, { style })}
                      key={index}
                    >
                      {suggestion.description}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </>
  );
};
export default SearchInput;
